<template>
  <div style="height:100%;width:100%;overflow:hidden">
    <div style="height: 100%" class="login">
      <img src="../../assets/image/beijing.jpg" alt="" style="width: 100%" />
      <div style="padding: 0 20px">
        <div class="login-part">
          <div v-if="$baseInfo.IS_CF==0">
            <button class="nq-button" style="margin-top: 10px" @click="weChat">
              微信登录
            </button>
          </div>
          <div class="center-order">
            <PageTab
              style="height: calc(100vh - 200px)"
              :current="tabCurrent"
              :tabs="[
                {
                  label: '登录',
                  value: 1,
                },
                {
                  label: '注册',
                  value: 2,
                },
              ]"
              @change="tabChange"
            >
              <!-- 放第一栏内容 -->

              <div v-if="tabCurrent === 0">
                <nq-form
                  class="denglu"
                  ref="loginForm"
                  :formList="[
                    {
                      label: '用户名',
                      key: 'userName',
                      type: 'input',
                      placeholder: '用户名',
                    },
                    {
                      label: '密码',
                      key: 'passWord',
                      type: 'input',
                      inputType: 'password',
                      placeholder: '密码',
                    },
                  ]"
                  :values="formValue"
                  @submit="submitForm"
                  @cancel="cancelForm"
                >
                </nq-form>
                <div class="bottom-part">
                  <button
                    v-loading="loginLoading"
                    class="nq-button denglv partOne"
                    @click="$refs.loginForm.submitForm()"
                  >
                    登录
                  </button>
                  <p style="font-size: 14px; margin-top: 10px" @click="forget">
                    忘记密码?
                  </p>
                </div>
              </div>
              <!-- 放第二栏内容 -->
              <div v-if="tabCurrent === 1">
                <nq-form
                  ref="registFormSubmit"
                  @submit="registFormSubmit"
                  @cancel="cancelRegistForm"
                  :values="formValue"
                  :formList="[
                    {
                      label: '用户名',
                      key: 'memberName',
                      type: 'input',
                      placeholder: '请输入用户名',
                    },
                    {
                      label: '手机号',
                      key: 'telephone',
                      type: 'input',
                      placeholder: '请输入手机号',
                    },
                    {
                      label: '密码',
                      key: 'passwords',
                      type: 'input',
                      inputType: 'password',
                      placeholder: '请输入密码',
                    },
                    {
                      label: '确认密码',
                      key: 'surePassword',
                      type: 'input',
                      placeholder: '请再次输入密码',
                      validate: [
                        (formList, item, index) => {
                          return {
                            values: [formList[3], item],
                            validateRes: formList[3].value === item.value,
                            message: '两次密码不一致',
                          };
                        },
                      ],
                    },
                  ]"
                >
                </nq-form>
                <div class="bottom-part">
                  <p>
                    <input
                      type="checkbox"
                      v-model="agree"
                      style="margin-right: 10px"
                    /><span>我同意</span>
                    <span style="color: red" @click="speak">《免责声明》</span>
                  </p>
                  <p style="color: red">
                    {{ agreeTips }}
                  </p>
                  <button
                    class="nq-button zhuce"
                    @click="$refs.registFormSubmit.submitForm()"
                  >
                    注册
                  </button>
                  <p style="font-size: 14px">
                    已经注册，点击
                    <span style="color: red" @click="loginAgain">登录</span>
                  </p>
                </div>
              </div>
            </PageTab>
            <nqDialog
              :title="msgTitle"
              :visible="megVisible"
              @close="megVisible = false"
            >
              <div v-if="msgTitle === '《'+$baseInfo.COMPANY_TITLE+'会员服务协议》'">
                <div class="speak">
                  <p>
                    免责声明
                  </p>
                  <p>
                    服务中可能发生的问题我们无法一一预告，主要总结了以下几点，请务必仔细阅读。当注册国际集运服务网会员即代表您认可并同意。
                  </p>
                  <p>
                    一.因付款不及时造成的发货延误，我公司不承担责任。国际集运服务网平台提供多种运输方式，您最终选择的运输方式即代表您默认该承运商的所有服务属性（主要包括运送时效、清关能力等）
                  </p>
                  <p>
                    二.本公司为中间服务商，非包裹承运商，国际运输前我们负责提供仓库代收物品、免费仓储管理、分拣、合并封装等服务，运输途中一旦发生快件丢失、破损、延误等情况我们将协助您与承运商或承运商代理之间办理查询、索赔等事宜，具体赔偿方案可参考会员中心：发货须知!
                  </p>
                  <p>
                    三.客户需对目的地进口国的关税起征点进行一定的了解，部分国家的进口关税较重，各个国家的规定不同，税金一般取决于商品的属性（个人生活用品或是商业用途）、重量、数量、品牌、种类、价值、体积、材质、用途等因素（燕窝、烟草、电子产品、药品、肉类等商品有很多国家是禁止进口的，请自行了解进口国的法律法规）。若海关要求收件人协助清关（例如提供详细的商品信息或进口许可证或购物发票等...）请务必配合，否则包裹将退回中国或当地销毁。部分国家的海关需收件人主动联系清关部门，逾期即退
                  </p>
                  <p>
                    四.禁止寄递的物品与限制寄递的物品请参考“禁限寄说明”，请用户自行遵守相关法律法规，如藏匿此类物品发生货物丢失或海关没收等情况请自行承担后果，情节严重者我们将报警处理
                  </p>
                  <p>
                    五.部分运输方式的计费与体积相关，主要有dhl、专线、ups、tnt、fedex、（ems单边长超40cm的包裹）请用户估算运费时将体积因素考虑在内
                  </p>
                  <p>
                    六.“费用估算”页面展示的费用不定期调整，以当下计算结果为准，费用已基本涵盖本公司所有收费项目（超规格、磁检、偏远派送、进口关税、“重复”打包等情况除外）。包裹被中国海关退运的情况下不产生运费，若从国外退回，当初您支付的国际运费不予退还，ems与e邮宝以外的运输方式将收取国外退回中国的运费及相关税金，您可以选择承担这笔费用拿回包裹或默认销毁
                  </p>
                  <p>
                    七.仓库内的物品（待处理、待发运）免费存储90天，逾期包裹本公司有权销毁、丢弃、廉价变卖、转增等方式处理，逾期自动清除不再另行通知。对于已逾期的包裹本公司不再受理用户申诉及任何赔偿事宜
                  </p>
                  <p>
                    八.包裹目的地派送时请查验后再签收，如遇商品缺损请拒收，并要求派送公司开具书面证明且自行拍照取证，若您签收后发现包裹内物品丢失或损坏，本司无法再追究承运商的任何责任（建议贵重物品封装打包前与我司客服进行核对他是否存在，是否完好无损）
                  </p>
                  <p>
                    九.限制寄递的物品如下：（香烟、雪茄、仿牌类商品遭遇海关查没或退运，我司不受理任何查询及赔偿事宜）
                    1.各类食品及调味料 2.国际一二线品牌类 3.毛皮制品 4.香烟、雪茄 5.音像制品 6.各类药品 7.化妆品 8.电子产品 9.情趣用品 10.三无产品（仿牌类物品情节严重的将追究法律责任），关于药品、液体、粉末、电子产品、保健品、食品类建议您选择“大包海运”或敏感品专线这2个渠道，若选择e邮宝/ems/大包air/大包sal被中国海关安检退运的包裹将扣除10元/件的验关费
                  </p>
                  <p>
                    十.在库包裹（未处理或已打包）若不需要国际快递您可以选择国内寄送，提交打包申请后系统在线下单选择国内地址发运即可，快递费中已包含本司服务费（入库、仓储、分拣打包、纸箱、人工费等），您也可以选择安排家人或朋友来库自提或自行安排快递公司取件，但需要支付一定的服务费给予我司。1.未打包的包裹您需要支付2元/kg。2.已整合打包的包裹您需要支付4元/kg
                  </p>
                  <p>
                    十一.海关申报方式分2种，1.授权我司酌情，2.严格按照用户（您）提交的数据为准。若选“酌情”，我司制单人员按照工作经验进行调整并申报，但我司不受理您后期申诉控告我方申报不合理导致的包裹被税、被扣、被拒绝进口、被退回中国等纠纷。该项目比较敏感，请结合目的地国家海关的相关政策和包裹内件物品的属性谨慎操作
                  </p>
                  <p>
                    感谢新老客户对我们信任与体谅，见证我们的成长，我们将做的更好，不辜负大家的期望！
                  </p>
                </div>
                <button class="nq-button" @click="megVisible = false">
                  关闭
                </button>
              </div>
              <div v-if="msgTitle === '微信登录'">
                <div>
                  <img src="../../assets/image/gzh.jpg" alt="" />
                  <div style="text-align: left; font-size: 16px; color: black">
                    截取此二维码并使用微信扫一扫，关注“国际集运服务网”公众号，点击右下角下单服务--在线下单即可直接授权微信登录
                  </div>
                  <div>
                    <button class="nq-button" @click="megVisible = false">
                      关闭
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="msgTitle === '密码重置'">
                <nq-form
                  ref="loginForm"
                  @submit="submitForm"
                  @cancel="cancelForm"
                  :values="passWordList"
                  :formList="[
                    {
                      label: '会员名',
                      key: 'userName',
                      type: 'input',
                      placeholder: '会员名/邮箱',
                    },
                    {
                      label: '手机号',
                      key: 'telNum',
                      type: 'input',
                      placeholder: '手机号',
                    },
                  ]"
                >
                </nq-form>
                <div style="text-align: center">
                  <button class="nq-button">发送验证码</button>
                </div>
              </div>
            </nqDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { htmlLoginAndRegist } from '../../utils/apis/apis'
export default {
  name: 'login',
  data() {
    return {
      agree: false,
      tabCurrent: 0,
      tab1PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab2PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      msgTitle: '',
      megVisible: false,
      loginLoading: false,
      agreeTips: '',
      formValue: {
        mailbox: '',
        userName: '',
        telPhone: '',
        password: '',
        surePassword: ''
      },
      passWordList: {
        mailbox: '',
        telNum: ''
      },
      firstCode: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 登录提交
    submitForm(params) {
      this.loginLoading = true
      this.$reqPost(htmlLoginAndRegist.login, params).then((res) => {
        this.loginLoading = false
        if (res.data.code === 200) {
          console.log(res);
          localStorage.userInfo = JSON.stringify(res.data.data)
          this.$router.push('/user-center')
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    // 登录取消
    cancelForm() {},
    // 提交注册
    registFormSubmit(params) {
      this.agreeTips = ''
      if (!this.agree) {
        this.agreeTips = '请勾选并阅读免责声明'
        return
      }

      this.$reqPost(htmlLoginAndRegist.regist, params).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('注册成功')
          location.reload()
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    // 取消注册
    cancelRegistForm() {},
    speak() {
      this.msgTitle = '《国际集运服务网公司会员服务协议》'
      this.megVisible = true
    },
    weChat() {
      this.msgTitle = '微信登录'
      this.megVisible = true
    },
    tabChange(index, item) {
      this.tabCurrent = index
      // console.log(this.tabCurrent);
    },
    loginAgain() {
      this.tabCurrent = 0
    },
    forget() {
      this.msgTitle = '密码重置'
      this.megVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
.login {
  position: relative;
}
.login-part {
  position: absolute;
  top: 10%;
  // left: 5%;
  height: 88%;
  width: 90%;
  padding: 8px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgb(209, 209, 209), 0 0 1px rgb(209, 209, 209),
    0 0 1px rgb(209, 209, 209);
}
/deep/ .tab-handler {
  padding: 0 70px;
}
/deep/ .denglu .form-item-label {
  margin-top: 20px;
}
/deep/ .form-item-label {
  text-align: left;
  margin-top: 10px;
}
/deep/ .error-text {
  height: 0 !important;
}
.bottom-part {
  // position: absolute;
  left: 16%;
  margin-top: 20px;
}
.bottom-part p {
  margin-bottom: 30px;
}
.speak {
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}
// /deep/ .close-icon img {
//   display: none;
// }
/deep/ .nq-button {
  margin: 20px 0;
}
/deep/ .bottom-text {
  display: none;
}
.denglv {
  width: 240px;
  margin-top: 50px;
}
.zhuce {
  width: 240px;
}
// /deep/ .page-main {
//  display: none !important;
// }
</style>
